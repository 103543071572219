<template>
  <div class="w-100 transition">
    <GenericReport
      ref="tabelaListagem"
      title="Listagem de lotes de esterilização"
      route="formularios/loteEsterilizacao"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      :fields="fields"
      tipoRelatorio="relatorioEsterilizacao"
      :row-clicked="rowClicked"
    />

    <Modal ref="materiaisLoteModal">
      <div>
        <div class="header">
          <label class="titleMateriaisModal">
            Materiais do lote #{{numLoteModal}}
          </label>
        </div>
        <b-table
          striped
          hover
          :items="itemsModal"
          :fields="fieldsModal"
          tbody-tr-class="pointer"
          sticky-header
          @row-clicked="modalRowClicked"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment';
import GenericReport from '@/components/Table/GenericReport';
import Modal from '@/components/Utils/Modal';

export default {
  name: 'listaLoteEsterilizacao',
  components: {
    GenericReport,
    Modal,
  },
  data() {
    return {
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
        id_tipo_teste: {
          type: 'genericSelect',
          route: 'tipoTeste',
          firstOption: {
            value: null,
            label: 'Teste',
          },
        },
        id_tipo_esterilizacao: {
          type: 'genericSelect',
          route: 'tipoEsterilizacao',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Processamento',
          },
        },
        id_equipamento: {
          type: 'genericSelect',
          route: 'equipamento',
          defaultValue: Number(this.$route.query && this.$route.query.equipamento),
          firstOption: {
            value: null,
            label: 'Equipamento',
          },
        },
      },
      sideFilters: {
        lote: {
          type: 'input',
          label: 'Lote',
          defaultValue: this.$route.query && this.$route.query.numLote,
        },
      },
      fields: [
        {
          label: 'Lote',
          key: 'lote',
        },
        {
          label: 'Equipamento',
          key: 'equipamento',
        },
        {
          label: 'Unidade',
          key: 'unidade',
        },
        {
          label: 'Data',
          key: 'data',
          formatter: (value) => moment(value).format('DD/MM/YY'),
        },
        {
          label: 'Teste',
          key: 'teste',
        },
      ],
      itemsModal: [],
      fieldsModal: [
        {
          label: 'ID',
          key: 'idMaterial',
        },
        {
          label: 'Tipo de material',
          key: 'tipoMaterial',
        },
        {
          label: 'Descrição',
          key: 'descMaterial',
        },
      ],
      numLoteModal: '',
    };
  },
  watch: {
    '$route.query': function changeQuery(query) {
      this.sideFilters = {
        lote: {
          type: 'input',
          label: 'Lote',
          defaultValue: query.numLote,
        },
      };
      this.topFilters = {
        ...this.topFilters,
        id_equipamento: {
          ...this.topFilters.id_equipamento,
          defaultValue: +query.equipamento,
        },
      };
      this.$nextTick(() => {
        this.$refs.tabelaListagem.initFiltersAndSearch();
      });
    },
  },
  methods: {
    rowClicked(item) {
      this.itemsModal = Object.values(item.materiais).length
        ? item.materiais.map((material) => ({
          idMaterial: material.id_material,
          tipoMaterial: material.tipoMaterial.nome,
          descMaterial: material.descricao,
        }))
        : [];

      if (Object.values(item.materiaisGenericos).length) {
        this.itemsModal = item.materiaisGenericos.reduce((accum, curr) => accum.concat({
          descMaterial: curr.descricao,
          idMaterial: 'Material Genérico',
          tipoMaterial: curr.tipoMaterialGenerico.nome,
        }), this.itemsModal);
      }
      this.numLoteModal = item.lote;
      this.$refs.materiaisLoteModal.show();
    },
    modalRowClicked(item) {
      if (!+item.idMaterial) {
        return;
      }
      // abre a rastreabilidade do material em outra aba
      const routeData = this.$router.resolve({ name: `material/rastrear/${item.idMaterial}` });
      window.open(routeData.location.name, '_blank');
    },
  },
};
</script>
