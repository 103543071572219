<template>
  <main class="pdfLandscapeContainer padding">
    <div class="header">
      <label class="title">{{ title }}</label>
      <img src="/logo.png" alt="" class="modal-logo" width="150px"/>
    </div>
    <div class="infoHeader">
      <b-row>
        <b-col>
          <label>Data de emissão</label>
          <br />
          <div class="info">
            {{ headerData.date || '-' }}
          </div>
          <br />
        </b-col>
        <b-col>
          <label>Hora</label>
          <br />
          <div class="info">
            {{ headerData.hour || '-' }}
          </div>
          <br />
        </b-col>
        <b-col>
          <label>Usuário</label>
          <br />
          <div class="info">
            {{ headerData.user ||'-' }}
          </div>
          <br />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <label class="subTitle">Informações do relatório</label>
          <br/>
          <div v-for="key in filtersKeys" v-bind:key="key">
            <label>{{`${key}: ${filterData[key]}`}}</label>
            <br/>
          </div>
        </b-col>
        <b-col v-if="filterData && (filterData.initDate || filterData.endDate)">
          <label>Periodo: De {{ filterData.initDate }} até {{ filterData.endDate }}</label>
          <br />
          <label>Total de registros: {{ headerData ? headerData.qtdMateriais : '-' }}</label>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        striped
        :items="materiais"
        :class="fontSize"
      />
    </div>
  </main>
</template>

<script>

export default {
  components: {},

  computed: {
    headers() {
      return this.materiais.length && Object.keys(this.materiais[0]);
    },
    filtersKeys() {
      return Object.keys(this.filterData).filter((key) => key !== 'initDate' && key !== 'endDate');
    },
    fontSize() {
      if (!this.headers || !this.headers.length) return 'fs-16';
      const cols = this.headers.length;
      if (cols <= 6) return 'fs-16';
      if (cols <= 8) return 'fs-14';
      if (cols <= 10) return 'fs-12';
      if (cols <= 12) return 'fs-10';
      return 'fs-9';
    },
  },

  props: {
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
    filterData: {
      type: Object,
      default() {
        return {};
      },
    },
    materiais: {
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.column-width {
  /* max-width: 100px; */
  overflow: hidden;
}

* {
  font-weight: 400;
  font-size: 16px;
}

.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.subTitle {
  font-weight: 600;
}

.pdfLandscapeContainer {
  width: 842pt;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.infoHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.infoHeader h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 10px;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}

</style>
