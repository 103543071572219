<template>
  <div :class="listClass" class="transition">
    <cardless-base id="report">
      <slot name="header">
        <PageTitle :title="title" />
      </slot>

      <b-row>
        <b-col :cols="colsPadrao ? '10' : '11'">
          <b-row>
            <b-col
              :cols="topFilters[key].type === 'rangeDate' ? 3 : 2"
              v-for="key in topFilterValidKeys"
              :key="key"
              style="margin-top:10px"
            >
              <RangeDatePicker
                v-if="topFilters[key].type === 'rangeDate'"
                :initDate="filters.initDate"
                :endDate="filters.endDate"
                :state="validateDate()"
                :isBusy="loading"
                @selected="selectedDateInDatePicker"
              />
              <GenericSelect
                v-if="topFilters[key].type === 'genericSelect'"
                :key="refresh"
                :ref="`select-${key}`"
                :value="filters[key]"
                :route="topFilters[key].route"
                :labelKey="topFilters[key].labelKey || 'nome'"
                v-validate="{ required: true }"
                @input="(v) => selectedInTopFiltersSelect(key, v)"
                :customFilters="topFilters[key].customFilters"
                :firstOption="topFilters[key].firstOption"
                :state="validateState(`select-${key}`)"
                :disabled="loading"
              />
              <b-form-select
                v-if="topFilters[key].type === 'select'"
                :ref="`select-${key}`"
                :value="filters[key]"
                @change="(v) => selectedInTopFiltersSelect(key, v)"
                :disabled="
                  loading ||
                  (!topFilters[key].options && fillSelectOptions(key) === null)
                "
                :state="validateState(`select-${key}`)"
                :options="topFilters[key].options || fillSelectOptions(key)"
                class="invision-input"
              >
                <template slot="first">
                  <option
                    :value="
                      topFilters[key].firstOption
                        ? topFilters[key].firstOption.value
                        : null
                    "
                  >
                    {{
                      topFilters[key].firstOption
                        ? topFilters[key].firstOption.label
                        : '-- Por favor, selecione uma opção --'
                    }}
                  </option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" align="right">
          <b-dropdown right variant="link">
            <template #button-content>
              <printer-icon color="#5E627A" />
            </template>
            <b-dropdown-item @click="exportData('csv')">CSV</b-dropdown-item>
            <b-dropdown-item @click="exportData('xlsx')">EXCEL</b-dropdown-item>
            <b-dropdown-item @click="exportData('pdf')">PDF</b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col v-if="showToggleByTipoRelatorio()" cols="1">
          <b-button
            @click="toggleFilters()"
            variant="none"
            class="btn toggle-button align-self-center"
            ><SlidersIcon v-b-tooltip.hover.top="'Filtrar'"
          /></b-button>
        </b-col>
      </b-row>
      <b-card v-if="loading" class="mt-2 mb-2">
        <b-row align="center" align-h="around">
          <b-spinner />
        </b-row>
      </b-card>
      <b-card v-else-if="errMsg" class="mt-2 mb-2">
        <b-row class="mr-1 ml-1" align-h="between">
          <p class="card-text">{{this.errMsg}}</p>
          <ChevronDownIcon v-b-toggle.collapse-1/>
        </b-row>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <p class="card-text">{{this.err}}</p>
          </b-card>
        </b-collapse>
      </b-card>
      <b-card v-else-if="items.length === 0 && !loading" class="mt-2 mb-2">
        <b-row align="center" align-h="around">
          <p class="card-text">Vazio</p>
        </b-row>
      </b-card>
      <b-table
        v-else
        striped
        hover
        :items="items"
        :fields="getFilterFields"
        @row-clicked="rowClicked"
        :tbody-tr-class="rowClass"
        class="mt-3 reportTable"
        responsive
      >
        <template #cell(equipamento)="{ value, item }">
          <span v-if="enableLinkTo">
            <router-link
              v-if="value.nome"
              class="link"
              :to="{
                name: 'relatorioEsterilizacaoEquipamento',
                params: { item, filters },
              }"
            >
              {{ value.nome }}
            </router-link>
            <router-link
              v-else
              class="link"
              :to="{
                name: 'relatorioEsterilizacaoEquipamento',
                params: { item, filters: {...filters, id_equipamento: -1, id_unidade: item.unidade.id_unidade} },
              }"
            >
              Sem equipamento
            </router-link>
          </span>
          <span v-else>
            {{ value.nome || value }}
          </span>
        </template>
        <template #cell(fotos)="{ value }">
          <eye-icon
            class="pointer"
            @click="openModalPhotos(value)"
            v-if="value"
          />
        </template>
        <template #cell(observacaoAbortoProcesso)="justificativaAbortoProcesso">
          <img
            src="../../assets/img/clipboard.svg"
            @click="justificativaAbortoProcesso.toggleDetails"
            v-bind:class="{'clipboard-icon': justificativaAbortoProcesso.item.observacaoAbortoProcesso,
            'd-none': !justificativaAbortoProcesso.item.observacaoAbortoProcesso
              || justificativaAbortoProcesso.item.observacaoAbortoProcesso === '-'}"
          />
        </template>
        <template #cell(justificativa)="justificativa">
          <img
            src="../../assets/img/clipboard.svg"
            @click="justificativa.toggleDetails"
            v-bind:class="{'clipboard-icon': justificativa.item.justificativa,
            'd-none': !justificativa.item.justificativa || justificativa.item.justificativa === '-'}"
          />
        </template>
        <template #cell(Observações)="observacoesPreparo">
          <img
            src="../../assets/img/clipboard.svg"
            @click="observacoesPreparo.toggleDetails"
            v-bind:class="{'clipboard-icon': observacoesPreparo.item.Observações,
            'd-none': !observacoesPreparo.item.Observações || observacoesPreparo.item.Observações === '-'}"
          />
        </template>
        <template #cell(tempoParado)="{ item }">
          <span v-bind:class="{
            'overFlowText': item.overFlow
          }">
            {{ item['tempoParado'] }}
          </span>
        </template>
        <template #cell(ciclo_urgencia)="{ value }">
          <check-icon
          v-if="value"
          class="w20px"
        />
        </template>
        <template #row-details="row">
          <b-card v-if="row.item.observacaoAbortoProcesso">
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Justificativa:</b></b-col>
              <b-col>{{ row.item.observacaoAbortoProcesso }}</b-col>
            </b-row>
          </b-card>
          <b-card v-else-if="row.item.Observações || row.item.justificativa">
            <b-row
              class="mb-2"
              v-if="(row.item.Observações && row.item.Observações !== '-') || detailsRow.showObservacoes"
            >
              <b-col sm="3" class="text-sm-right"><b>Observações:</b></b-col>
              <b-col>{{ row.item.Observações }}</b-col>
            </b-row>
            <b-row
              class="mb-2"
              v-if="(row.item.justificativa && row.item.justificativa !== '-') || detailsRow.showJustificativa"
            >
              <b-col sm="3" class="text-sm-right"><b>Justificativa:</b></b-col>
              <b-col>{{ row.item.justificativa }}</b-col>
            </b-row>
          </b-card>
          <b-card
            v-else-if="
              !row.item.Observações && !row.item.observacaoAbortoProcesso && !row.justificativa
            "
          >
            <b-row class="mb-2">
              <b-col sm="12" class="text-sm-center"
                ><b
                  >Não foram registradas justificativas ou observações!</b
                ></b-col
              >
            </b-row>
          </b-card>
        </template>
      </b-table>
      <nav
        aria-label="Navegar entre as páginas"
        class="paginador"
        v-if="totalItems > 0 && !this.errMsg"
      >
        <div>
          Mostrando resultados {{ first }} a {{ last }} de um total de
          <strong>{{ totalItems }}</strong
          >.
        </div>
        <ul
          class="pagination justify-content-center mb-0"
          v-if="totalItems > rowsPerPage"
        >
          <b-pagination
            :value="page"
            @input="handleChangePage"
            :total-rows="totalItems"
            :per-page="rowsPerPage"
          ></b-pagination>
        </ul>
      </nav>
    </cardless-base>
    <div class="transition filters-aside p-3" :class="filterAsideClasses">
      <b-row align-h="between">
        <b-col> <h2 class="m-0"> Filtro </h2> </b-col>
        <b-col class="text-right">
          <b-button
            type="button"
            variant="none"
            class="sm primary-light-contained-button"
            :disabled="loading"
            @click="handleSearch"
            >Buscar</b-button
          >
        </b-col>
      </b-row>
      <b-row
        v-for="key in sideFiltersKeys"
        :key="key"
      >
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group
            :label="sideFilters[key].label"
          >
            <div v-if="sideFilters[key].type === 'input'">
              <b-form-input
                v-if="sideFilters[key].filterTeclas"
                :value="filters[key]"
                autocomplete="off"
                @keypress="filterTeclas($event)"
                class="invision-input sm"
                @input="(v) => handleChangeSideFilters(key, v)"
              ></b-form-input>
              <b-form-input
                v-else
                :value="filters[key]"
                autocomplete="off"
                class="invision-input sm"
                @input="(v) => handleChangeSideFilters(key, v)"
              ></b-form-input>
            </div>
            <GenericSelect
              v-if="sideFilters[key].type === 'genericSelect'"
              :value="filters[key]"
              :route="sideFilters[key].route"
              labelKey="nome"
              v-validate="{ required: true }"
              @input="(v) => handleChangeSideFilters(key, v)"
              :state="validateState(`select-${key}`)"
              :disabled="loading"
            />
            <b-form-group
              v-if="sideFilters[key].type === 'tipoMaterialGenerico'"
            >
              <b-form-select
                class="invision-input"
                ref="selectTipoMaterialGenerico"
                :value="filters[key]"
                @input="(v) => tipoMaterialChanged(key, v)"
                :options="tipoMaterialGenericoOptions"
                :state="validateState(`select-${key}`)"
                :disabled="loading"
              >
                <template slot="first">
                  <option :value="null">
                    -- Selecione uma opção --
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="sideFilters[key].type === 'descricaoGenerico'"
            >
              <v-select
                class="invision-select-typing"
                placeholder="-- Selecione uma opção --"
                ref="selectMaterialGenerico"
                v-model="materialGenericoSelected"
                :state="validateState(`select-${key}`)"
                :disabled="loading"
                :options="materiaisGenericos"
              >
                <template #no-options>
                  <em style="opacity: 0.5">Nenhuma opção disponível</em>
                </template>
              </v-select>
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <Modal
      id="modalPhoto"
      ref="modalPhoto"
      nome="modalPhoto"
      title="Fotos do Material"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
      <PhotoForm
        :photos="photosUrls"
        :onlyVisualize="true"
        downloadRoute="report/fotosFormularios"/>
    </Modal>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="600px"
      :html-to-pdf-options="htmlToPdfOptions"
      ref="html2Pdf"
      @hasDownloaded="finishGeneratePdf"
    >
      <section slot="pdf-content">
        <RelatorioPDF
          :title="relatorioTitles[tipoRelatorio] || title"
          :materiais="materiais"
          :filterData="lastGeneratedRelatorio"
          :headerData="headerData"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import XLSX from 'xlsx';
import VueHtml2pdf from 'vue-html2pdf';
import { exportCSVFile } from '@/helpers/common';
import login from '@/services/login';
import GenericApi from '@/services/genericRequest';
import CardlessBase from '@/templates/CardlessBase';
import PageTitle from '@/templates/PageTitle';
import RangeDatePicker from '@/components/Form/RangeDatePicker';
import GenericSelect from '@/components/Form/GenericSelect';
import PhotoForm from '@/components/WebCam/PhotoForm';
import Modal from '@/components/Utils/Modal';
import RelatorioPDF from './RelatorioPDF';

const momentFilters = ['initDate', 'endDate'];

export default {
  name: 'GenericReport',
  components: {
    CardlessBase,
    PageTitle,
    RangeDatePicker,
    GenericSelect,
    PhotoForm,
    Modal,
    VueHtml2pdf,
    RelatorioPDF,
  },
  data() {
    return {
      refresh: 0,
      items: [],
      errMsg: '',
      err: null,
      frontPagination: false,
      allItems: [],
      page: 1,
      rowsPerPage: 20,
      totalItems: 0,
      loading: false,
      filters: {},
      photosUrls: [],
      lastGeneratedRelatorio: {},
      materiais: [],
      relatorioTitles: {
        relatorioAbortoProcessos: 'Relatório de abortos',
        relatorioProcessos: 'Relatório de processos',
        relatorioEsterilizacao: 'Relatório de esterilização',
        relatorioMateriaisOPME: 'Relatório de materiais OPME',
        relatorioNaoConformidades: 'Relatório de não conformidades',
        relatorioMateriaisMedicos: 'Relatório de materiais médicos',
        relatorioEquipamentoEsterilizacao:
          'Relatório esterilização por equipamento',
        relatorioInventario: 'Relatório de inventário',
        encerramentosForcados: 'Relatório de encerramento de ciclos forçados',
        relatorioMateriaisParados: 'Relatório de materiais parados',
        relatorioItens: 'Relatório de inventário de instrumentos',
      },
      headerData: {},
      showingFilters: false,
      colsPadrao: true,
      tipoMaterialGenericoOptions: [],
      materiaisGenericos: [],
      materialGenericoSelected: null,
      configuracaoRelatorio: null,
      tiposProcessos: null,
      haveFilterFields: false,
      fieldsToExport: [],
      allOptionsTiposProcessos: null,
      detailsRow: {
        showObservacoes: false,
        showJustificativa: false,
      },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
    fields: {
      type: Array,
      required: false,
    },
    topFilters: {
      type: Object,
      default: () => {},
    },
    rowClicked: {
      type: Function,
      default: () => {},
    },
    rowCursorPointer: {
      type: Boolean,
      default: false,
    },
    tipoRelatorio: {
      type: String,
    },
    sideFilters: {
      type: Object,
      default: () => {},
    },
    unidades: {
      type: Array,
      required: false,
    },
    enableLinkTo: {
      type: Boolean,
      required: false,
      default: false,
    },
    pathLinkTo: {
      type: String,
      required: false,
    },
    fieldsFiltered: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState(['materiaisGenericosByTipo', 'genericData']),
    htmlToPdfOptions() {
      return {
        margin: [10, 0, 10, 0],

        filename: `${this.tipoRelatorio}.pdf`,

        image: {
          type: 'jpeg',
          quality: 0.98,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true,
        },

        jsPDF: {
          format: 'a4',
          orientation: 'landscape',
          floatPrecision: 16,
        },
      };
    },
    rowClass() {
      return this.rowCursorPointer ? 'pointer' : '';
    },
    topFiltersKeys() {
      return this.topFilters ? Object.keys(this.topFilters) : [];
    },
    sideFiltersKeys() {
      return this.sideFilters ? Object.keys(this.sideFilters) : [];
    },
    first() {
      return (this.page - 1) * this.rowsPerPage + 1;
    },
    last() {
      const maxInPage = this.page * this.rowsPerPage;
      return this.totalItems < maxInPage ? this.totalItems : maxInPage;
    },
    filterAsideClasses() {
      return this.showingFilters ? 'show' : 'hidden';
    },
    listClass() {
      return this.showingFilters ? 'w-75' : 'w-100';
    },
    topFilterValidKeys() {
      if (!this.topFilters) return [];
      const topFilterValidKeys = Object.keys(this.topFilters).filter((filterKey) => {
        const filterDependency = this.topFilters[filterKey].dependency;
        if (!filterDependency) return true;
        return filterDependency.values.includes(String(this.filters[filterDependency.key]));
      });

      return topFilterValidKeys;
    },
    getFilterFields() {
      if (!this.haveFilterFields) return this.fields;
      let fieldsReport = null;
      if (this.configuracaoRelatorio.fields) {
        const chaveFields = this.tiposProcessos && Array.isArray(this.tiposProcessos)
          ? this.tiposProcessos.find((tp) => tp.id_tipo_processo === this.filters.id_tipo_processo)
          : null;

        fieldsReport = chaveFields && chaveFields.nome
          ? this.configuracaoRelatorio.fields[`${chaveFields.nome}`]
          : this.configuracaoRelatorio.fields.geral || null;
      }

      return fieldsReport;
    },

  },
  watch: {
    materialGenericoSelected: {
      async handler(val) {
        this.filters.descricaoGenerico = val.descricao;
      },
    },
    getFilterFields: {
      handler(fields) {
        this.getFieldsReport(fields);
        this.getFieldsDetailsRow();
      },
    },
  },
  async mounted() {
    this.configuracaoRelatorio = await GenericApi.get({},
      `configuracaoRelatorio/${this.tipoRelatorio.toUpperCase()}`);
    this.haveFilterFields = Boolean(this.configuracaoRelatorio.fields && !this.fieldsFiltered);
    this.getFieldsDetailsRow();
    this.initFiltersAndSearch();
  },

  methods: {
    ...mapActions(['getMateriaisGenericosByTipo']),
    async initFiltersAndSearch() {
      this.filters = this.topFiltersKeys.reduce((Acum, key) => {
        if (this.topFilters[key].type === 'rangeDate') {
          return {
            ...Acum,
            initDate: this.topFilters[key].defaultInitDate || null,
            endDate: this.topFilters[key].defaultEndDate || null,
          };
        }
        return { ...Acum, [key]: this.topFilters[key].defaultValue || null };
      }, {});
      this.filters = this.sideFiltersKeys.reduce((Acum, key) => ({
        ...Acum, [key]: this.sideFilters[key].defaultValue || null,
      }), { ...this.filters });
      if (this.sideFiltersKeys.some((key) => this.sideFilters[key].type === 'tipoMaterialGenerico')) {
        if (!this.materiaisGenericosByTipo) {
          this.loadingTipo = true;
          await this.getMateriaisGenericosByTipo();
        }
        this.fillTipoMaterialGenericoOptions();
      }
      this.getTiposProcessos();
      this.handleSearch();
    },
    filterTeclas($event) {
      if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
        return true;
      }
      $event.preventDefault();
      return false;
    },
    showToggleByTipoRelatorio() {
      if (this.sideFilters && Object.keys(this.sideFilters).length) {
        return true;
      }
      this.colsPadrao = false;
      return false;
    },
    handleSearch() {
      this.page = 1;

      if (this.tipoRelatorio === 'relatorioEquipamentoEsterilizacao') {
        this.$store.commit('setFiltersStored', { relatorioEquipamentoEsterilizacao: { ...this.filters } });
      }

      this.update();
    },
    toggleFilters() {
      this.showingFilters = !this.showingFilters;
    },
    selectedDateInDatePicker(initDate, endDate) {
      this.filters.initDate = initDate;
      this.filters.endDate = endDate;
      this.handleSearch();
    },
    selectedInTopFiltersSelect(prop, value) {
      this.filters[prop] = value;
      if (prop === 'id_unidade' && this.tipoRelatorio === 'relatorioProcessos') {
        this.$emit('updateTopFilters', value);
        this.refresh += 1;
        if (this.$refs['select-id_sub_setor']) this.handleChangeSideFilters('id_sub_setor', null);
        if (this.$refs['select-id_tipo_processo']) this.handleChangeSideFilters('id_tipo_processo', null);
      }
      if (prop === 'id_sub_setor' && this.$refs['select-id_tipo_processo']) {
        const options = this.fillSelectOptions('id_tipo_processo', value);
        if (value) {
          const minOptionValue = options.reduce((min, curr) => {
            if (!min || curr.value < min) return curr.value;
            return min;
          }, 0);
          this.filters.id_tipo_processo = minOptionValue;
        } else {
          this.filters.id_tipo_processo = null;
        }
      }
      if (prop === 'id_unidade' && this.$refs['select-id_setor']) {
        this.filters.id_setor = null;
      }

      this.handleSearch();
    },
    handleChangeSideFilters(prop, value) {
      this.filters[prop] = value;
    },
    getFormattedDate(initDate, endDate) {
      return `${initDate.format('DD/MM/YY')} a ${
        endDate ? endDate.format('DD/MM/YY') : '-'
      }`;
    },
    validateState(ref) {
      if (!this.$refs[ref] || !this.$refs[ref][0] || this.$refs[ref][0].value === null) return null;
      if (this.$refs[ref] && this.$refs[ref][0].value) return true;
      return false;
    },
    validateDate() {
      if (!this.filters.initDate || !this.filters.endDate) return false;
      return true;
    },
    fillSelectOptions(key, parentValue = null) {
      if (key === 'id_tipo_processo' && this.$refs['select-id_sub_setor']) {
        const selectedIdSubSetor = parentValue || this.$refs['select-id_sub_setor'][0].value;
        if (!selectedIdSubSetor) return null;
        const subSetor = this.$refs['select-id_sub_setor'][0].apiResponse.find(
          (item) => item.id_sub_setor === selectedIdSubSetor,
        );
        if (!subSetor) return null;
        const tipoProcessoOptions = subSetor.tipoProcesso.map(
          (tipoProcesso) => ({
            value: tipoProcesso.id_tipo_processo,
            text: tipoProcesso.nome,
          }),
        );
        tipoProcessoOptions.sort((a, b) => {
          if (a.text.toUpperCase() < b.text.toUpperCase()) return -1;
          return 1;
        });
        if (this.tiposProcessos) {
          if (parentValue) {
            this.$refs['select-id_tipo_processo'][0].options = tipoProcessoOptions;
          } else {
            this.$refs['select-id_tipo_processo'][0].options = this.allOptionsTiposProcessos
              && this.allOptionsTiposProcessos.map((tp) => ({
                value: tp.id_tipo_processo,
                text: tp.nome,
              }));
          }
        }
        return tipoProcessoOptions;
      }
      if (
        key === 'id_equipamento' && this.$refs['select-id_tipo_esterilizacao']
      ) {
        const { apiResponse, value } = this.$refs[
          'select-id_tipo_esterilizacao'
        ][0];
        const selectedIdTipoEsterilizacao = parentValue || value;
        if (!selectedIdTipoEsterilizacao) return null;
        const tipoEsterilizacao = apiResponse.find(
          (item) => item.id_tipo_esterilizacao === selectedIdTipoEsterilizacao,
        );
        if (!tipoEsterilizacao || !tipoEsterilizacao.equipamentos.length) return null;
        let { equipamentos } = tipoEsterilizacao;
        if (
          this.$refs['select-id_unidade']
          && this.$refs['select-id_unidade'][0].value
        ) {
          equipamentos = equipamentos.filter(
            (equip) => equip.id_unidade === this.$refs['select-id_unidade'][0].value,
          );
        }
        return equipamentos.map((equipamento) => ({
          text: equipamento.nome,
          value: equipamento.id_equipamento,
        }));
      }
      if (key === 'id_setor' && this.$refs['select-id_unidade']) {
        const { apiResponse, value } = this.$refs['select-id_unidade'][0];
        let setor = null;
        const selectedIdUnidade = parentValue || value;
        if (!selectedIdUnidade) return null;
        if (!apiResponse) {
          const unidadeStore = this.unidades.find((elem) => elem.id_unidade === selectedIdUnidade);
          if (!unidadeStore) return null;
          setor = unidadeStore.setor;
        } else {
          const unidade = apiResponse.find((item) => item.id_unidade === selectedIdUnidade);
          if (!unidade || !unidade.setor.length) return null;
          setor = unidade.setor;
        }
        return setor
          .map((s) => ({
            text: s.nome,
            value: s.id_setor,
          }))
          .sort((a, b) => {
            if (a.text.toUpperCase() < b.text.toUpperCase()) return -1;
            return 1;
          });
      }
      return null;
    },
    update() {
      if (!this.route) return false;
      const { page, rowsPerPage } = this;
      this.loading = true;
      const validFilters = this.getValidFilters();
      return GenericApi.get(
        { filters: validFilters, page, rowsPerPage },
        this.route,
      )
        .then((res) => {
          if (res.rows) {
            // com paginação
            this.items = res.rows;
            this.totalItems = res.count;
          } else {
            const offset = (page - 1) * rowsPerPage;
            this.totalItems = res.length;
            this.items = res.slice(offset, offset + rowsPerPage);
            this.frontPagination = true;
            this.allItems = res;
          }
          this.errMsg = '';
        })
        .catch((err) => {
          console.error(err);
          this.err = err;
          this.items = [];
          this.errMsg = `Erro ao carregar '${this.title}', por favor tente novamente.`;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async exportData(type) {
      try {
        this.loading = true;
        const validFilters = this.getValidFilters();
        let data = this.route
          ? await GenericApi.getWithoutPagination(
            { filters: validFilters },
            this.route,
          )
          : this.items;

        if (data.rows) data = data.rows;

        if (!this.fieldsToExport.length) {
          this.fieldsToExport = this.fields;
        }
        if (this.fieldsToExport && this.fieldsToExport.length) {
          data = data.map((row) => this.fieldsToExport.reduce((acum, field) => {
            const formatter = field.formatter || field.formatterSlot;
            const key = field.label || field.key;
            if (key === 'Fotos') return { ...acum };
            let value;
            if (!row[field.key]) {
              value = '-';
            } else if (!formatter && typeof row[field.key] === 'object') {
              value = row[field.key].descricao || row[field.key].nome || '-';
            } else if (formatter) {
              value = formatter(row[field.key]);
            } else {
              value = row[field.key];
            }
            return {
              ...acum,
              [key]: value,
            };
          }, {}));
        }
        data = data.map((row, index) => ({ '#': index + 1, ...row }));
        if (type === 'csv') {
          exportCSVFile(data.rows || data, 'relatorio');
          this.loading = false;
        } else if (type === 'xlsx') {
          const ws = XLSX.utils.json_to_sheet(data.rows || data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, ws, 'pagina 1');
          XLSX.writeFile(workbook, 'relatorio.xlsx');
          this.loading = false;
        } else {
          // type === 'pdf'
          const user = login.getUser();
          this.headerData = {
            date: moment().format('DD/MM/YYYY'),
            hour: moment().format('HH:mm:ss'),
            user: user.nome,
            qtdMateriais: data.length,
          };

          this.materiais = data;
          this.lastGeneratedRelatorio = this.topFilters && Object.keys(validFilters).reduce((acum, key) => {
            if (!this.$refs[`select-${key}`]) return acum;
            if (key === 'initDate' || key === 'endDate') {
              return {
                ...acum,
                [key]: this.filters[key].format('DD/MM/YY'),
              };
            }
            const { options, firstOption, $slots } = this.$refs[
              `select-${key}`
            ][0];
            const informacao = options.find((elem) => elem.value === this.filters[key]);
            return {
              ...acum,
              [firstOption
                ? firstOption.label
                : $slots.first[0].children[0].text]: informacao.text,
            };
          }, {});
          this.$refs.html2Pdf.generatePdf();
        }
      } catch (e) {
        console.log(e);
        swal({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao exportar tabela',
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.loading = false;
      }
    },
    getValidFilters() {
      return Object.keys(this.filters).reduce((Acum, key) => {
        const filters = { ...this.filters };
        const caracteresEspeciais = ['+', '=', '#', '&', ':'];
        if (filters.descricao) {
          caracteresEspeciais.forEach((char, index) => {
            const indexChar = filters.descricao.indexOf(caracteresEspeciais[index]);
            if (indexChar !== -1) {
              filters.descricao = filters.descricao.replace(
                filters.descricao[indexChar], encodeURIComponent(char),
              );
            }
          });
        }
        const dateRangeKeys = ['initDate', 'endDate'];
        if (!filters[key]) return Acum;
        if (!this.topFilterValidKeys.includes(key)
          && !this.sideFiltersKeys.includes(key)
          && !dateRangeKeys.includes(key)) {
          return Acum;
        }
        return {
          ...Acum,
          [key]: momentFilters.includes(key)
            ? filters[key].toISOString()
            : filters[key],
        };
      }, {});
    },
    openModalPhotos(urls) {
      this.photosUrls = urls;
      this.$refs.modalPhoto.show();
    },
    finishGeneratePdf() {
      this.loading = false;
    },
    handleChangePage(newPage) {
      this.page = newPage;
      if (!this.frontPagination) {
        this.update();
      } else {
        const offset = (newPage - 1) * this.rowsPerPage;
        this.items = this.allItems.slice(offset, offset + this.rowsPerPage);
      }
    },
    fillTipoMaterialGenericoOptions() {
      this.tipoMaterialGenericoOptions = this.materiaisGenericosByTipo.reduce((acc, tmg) => [
        ...acc,
        { text: tmg.nome, value: tmg.id_tipo_material_generico },
      ], []);
      if (this.tipoMaterialGenericoOptions.length === 1) {
        this.tipoMaterialChanged(this.tipoMaterialGenericoOptions[0].value);
      }
    },
    tipoMaterialChanged(prop, v) {
      this.filters[prop] = v;
      if (v) {
        const tmg = this.materiaisGenericosByTipo.find(
          (matGeneric) => matGeneric.id_tipo_material_generico === v,
        );

        this.materiaisGenericos = tmg.materialGenerico.map((mg) => ({
          ...mg,
          tipoMaterialGenerico: {
            nome: tmg.nome,
            id_tipo_material_generico: tmg.id_tipo_material_generico,
          },
        }));
      } else {
        this.materiaisGenericos = [];
        this.materialGenerico = null;
      }
    },
    getTiposProcessos() {
      if (!this.$refs['select-id_tipo_processo']
        || !this.$refs['select-id_tipo_processo'][0].apiResponse
        || !Array.isArray(this.$refs['select-id_tipo_processo'][0].apiResponse)
      ) return;

      this.tiposProcessos = this.$refs['select-id_tipo_processo'][0].apiResponse.map((tp) => ({
        nome: tp.nome,
        id_tipo_processo: tp.id_tipo_processo,
      }));

      if (!this.allOptionsTiposProcessos) {
        this.allOptionsTiposProcessos = this.$refs['select-id_tipo_processo'][0].apiResponse.map((tp) => ({
          nome: tp.nome,
          id_tipo_processo: tp.id_tipo_processo,
        }));
      }
    },
    getFieldsReport(fieldsReport) {
      this.fieldsToExport = fieldsReport
        ? fieldsReport.reduce((acc, field) => {
          if (field.label && field.key) return [...acc, { label: field.label, key: field.key }];
          return [...acc, { label: field, key: field }];
        }, [])
        : this.fields;
    },
    getFieldsDetailsRow() {
      this.detailsRow.showObservacoes = !this.fieldsToExport.length;
      this.detailsRow.showJustificativa = !this.fieldsToExport.length;

      this.fieldsToExport.forEach((field) => {
        if (field.key === 'Observações') this.detailsRow.showObservacoes = true;

        if (field.key === 'justificativa') this.detailsRow.showJustificativa = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;
$neutral-color-80: #35384D;

.filters-aside {
  z-index: 100;
  position: fixed;
  min-height: 100%;
  right: 0;
  top: 64px;
  background-color: white;
}
.filters-aside.show {
  width: 22%;
}
.filters-aside.hidden {
  width: 0 !important;
  padding: 0 !important;
}

.toggle-button {
  color: $neutral-color-80;
}

.link {
  color: #0BB390;
}

/* selected link */
.link:active {
  color: #01856A;
}

//pdf Template style
@media print {
  @page {
    margin: 25mm 25mm 25mm 25mm;
  }
}
.pdfStyle {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 10px;
  margin: 25mm 25mm 25mm 25mm;
}

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

.reportTable {
  background-color: #fff;
  border: white 10px solid;
}

#report table thead th {
  color: #7e829f;
  padding-bottom: 50px;
  font-weight: 900;
}
.loadingReport {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.spinnerReport {
  color: $primary-color-50;
}
.bgc-white {
  background-color: #fff;
}

.clipboard-icon {
  cursor: pointer;
  margin-left: 35px;
}

.overFlowText {
  color: red;
}
.result-message{
  text-align: center;
  background: white;
}
.form-group {
  margin-bottom: 0.4rem;
}
</style>

<style>
.invision-select-typing .vs__dropdown-toggle {
  height: 100%;
  padding: 0;
  border-radius: 7px;
  border: 1px solid #ced4da;
}
.invision-select-typing .vs__selected {
  padding: 4.5px 16px;
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__search {
  padding: 0.4rem;
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__dropdown-option {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
</style>
